import {
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { useEffect, useState } from "react";
// import Link from "next/link";

const GiftCheckout = (props) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const price = props.price;
  const card_id = props.card_id;
  const dollar_price = price * 100;
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const song_link = props.link;
  const art = props.art;
  const uuid = props.uuid;
  const issued = props.issued;
  const image = props.image;

  // console.log(dollar_price);

  useEffect(() => {
    // console.log('gift stripe ' + props.name + props.mail)

    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Purchase",
          amount: dollar_price,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        // applePay: true,
        // googlePay: true,
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  const handlePaymentSuccess = (paymentMethod) => {
    // console.log(props.card_id);

    // Set the payment success flag
    setPaymentSuccess(true);

    // Get the payer's email address from the paymentMethod object
    const email_address = paymentMethod.billing_details.email;

    // Define the data object to be sent in the request
    const message = `<p><b><i>Want your name on the <a href="https://beta.songcards.io/cards/${uuid}">Songcard?</a></i></b> Reply with a 'YES'!</p><p>If you have not done so already, login / sign up <a href="https://beta.songcards.io/account">HERE</a> with the same email address I reach you on.</p><p>Once we have associated the purchase with your account, you will be able to login, download the song, and unlock the bonus content.</p><p>If you have any issues, please feel free to reply to this email.</p><p>Discover <a href="https://beta.songcards.io/discover">more Songcards</a></p>`;
    const data = {
      to: "chris@unlockyoursound.com",
      subject: `💜 Your friend ${props.name} bought you a Songcard!`,
      message: message,
      card_id: card_id,
    };

    axios
      .post("https://api.songcards.io/ninja/purchase", {
        email_address: email_address,
        card_id: card_id,
        subject: "💜 Your Songcard is here!",
        message: message,
      })
      .then((response) => {
        // handle the response from the server
        // console.log(response.data);
      })
      .catch((error) => {
        alert(error);
        // handle any errors that occur
        // console.log(error);
      });
  };

  if (paymentRequest) {
    paymentRequest.on("paymentmethod", async (ev) => {
      const response = await axios.post(
        "https://api.songcards.io/ninja/create-payment-intent",
        {
          amount: dollar_price,
          automatic_payment_methods: {
            enabled: true,
          },
          currency: "usd",
        }
      );
      const clientSecret = response.data.client_secret;

      const { paymentIntent, error: confirmError } =
        await stripe.confirmCardPayment(
          clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        );

      if (confirmError) {
        ev.complete("fail");
      } else {
        ev.complete("success");

        if (paymentIntent.status === "requires_action") {
          const { error } = await stripe.confirmCardPayment(clientSecret);
          if (error) {
            // The payment failed -- ask your customer for a new payment method.
          } else {
            handlePaymentSuccess(ev.paymentMethod);
          }
        } else if (paymentIntent.status === "succeeded") {
          handlePaymentSuccess(ev.paymentMethod);
        }
      }
    });
    return (
      <>
        {paymentSuccess ? (
          <>
            <h1>
              <b>Success! 💜</b> Your friend{`'`}s Songcard will be delivered to
              their inbox shortly!
            </h1>
            <br></br>
          </>
        ) : null}
        <PaymentRequestButtonElement options={{ paymentRequest }} />
      </>
    );
  }

  return (
    <div>
      <br />
      <p>The checkout button is currently compatible with Chrome and Safari.</p>
      <br />
      <p>Support for other browsers coming soon.</p>
      <br />
      <p>
        {
          "If you'd like to own this Songcard, you can load this page on Chrome or Safari."
        }
      </p>
    </div>
  );
};

export default GiftCheckout;
