import { ListItem, UnorderedList } from "@chakra-ui/react";

const Includes = ({ includesList }) => {
  return (
    <>
      <UnorderedList>
        <ListItem>Unlimited streaming</ListItem>
        <ListItem>
          Listen on the go in the iOS app (Android coming soon)
        </ListItem>
        <ListItem>First dibs on future Songcards</ListItem>
        <ListItem>Listing on your collectors page</ListItem>
        <ListItem>Proof Of Fan</ListItem>
      </UnorderedList>
    </>
  );
};

export default Includes;
