import { Box, Center } from "@chakra-ui/react";
import Image from "next/image";
import { z } from "zod";

export const ThumbnailProps = z.object({
  ext_file: z.string().nullish(),
  comp_file: z.string().nullish(),
});

const Thumbnail = (props: z.infer<typeof ThumbnailProps>) => {
  // const validationResult = ThumbnailProps.safeParse(props);

  return (
    <Center>
      <Box
        borderRadius="md"
        maxWidth={350}
        minWidth={260}
        minHeight={260}
        overflow="hidden"
      >
        <Image
          src={props.comp_file ?? props.ext_file ?? ""}
          alt="Thumbnail"
          height={380}
          width={380}
        />
      </Box>
    </Center>
  );
};

export default Thumbnail;
