import { extendTheme } from "@chakra-ui/react"

export const theme = extendTheme({
  config: {
    initialColorMode: "system",
    useSystemColorMode: true,
  },
  dark: {
    900: "black",
    100: "#151515",
    300: "121212",
  },
  light: {
    100: "white",
  },
  styles: {
    global: (props: { colorMode: string}) => ({
      'html, body': {
        color: 'white.600',
        bg: props.colorMode === "dark" ? "black" : "#ffffff",
        minHeight: 1000,
      },
    }),
  },
})