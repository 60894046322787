import { Center, useColorMode } from "@chakra-ui/react";
import { motion } from "framer-motion";
import Image from "next/image";

export default function Loading() {
  const { colorMode } = useColorMode();
  return (
    <>
      <motion.div
        animate={{
          //   scale: [1, 2, 2, 1, 1],
          rotate: [0, -15, 15, -15, 0],
          borderRadius: ["20%", "20%", "50%", "50%", "20%"],
        }}
        transition={{ repeat: Infinity, duration: 2 }}
      >
        <Center mt={100} mb={2000}>
          {/* <p>loading</p> */}
          <Image
            src={
              colorMode === "dark"
                ? "/SC Icon_B_White.svg"
                : "/SC Icon_B_Black.svg"
            }
            width={40}
            height={500}
            alt="Discover more Songcards"
          />
        </Center>
      </motion.div>
    </>
  );
}
